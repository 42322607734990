import React from "react";

const juneImage = new URL("/src/assets/junedetail.jpg", import.meta.url);

const images = [
  new URL("/src/assets/junefront.jpg", import.meta.url),
  new URL("/src/assets/fabrijune.jpg", import.meta.url),
  new URL("/src/assets/junehalf.jpg", import.meta.url),
  // Add more images as needed
];

const Products = () => {
  return (
    <>
      <div className="relative">
        <div className="fixed mt-[-75px] w-screen -z-10">
          <img
            src={juneImage}
            alt="june baththube"
            className="fixed mt-[-75px] w-screen -z-10"
          />
        </div>
        <div className="pt-16 pb-10 sm:pt-16 sm:pb-16 lg:pb-16">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-10 bg-stone-600/40 rounded-3xl">
            <h2 className="text-center text-3xl font-bold leading-tight gray-primary-color sm:text-4xl lg:text-5xl mb-8">
              Onze Producten
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {images.map((image, index) => (
                <div key={index} className="flex justify-center">
                  <div className="rounded-lg shadow-lg overflow-hidden bg-white">
                    <img
                      src={image}
                      alt={`Product ${index + 1}`}
                      className="object-cover w-full h-full transition-transform duration-200 hover:scale-105"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
