import React from "react";
import mobile from "../assets/mobileR.png";

const juneImage = new URL("/src/assets/junedetail.jpg", import.meta.url);
const bathroom2 = new URL("/src/assets/fabrijunevert1.jpg", import.meta.url);

const About = () => {
  return (
    <>
      <img
        src={juneImage}
        alt="june baththube"
        className="fixed mt-[-75px] w-screen -z-10"
      />
      <div className="p-8 pt-8 sm:pt-16 sm:pb-16 lg:pb-16 md:h-[92vh]">
        <div className="flex flex-col md:flex-row py-16 mb-6 overflow-hidden md:pt-0 sm:pt-16 2xl:pt-16 relative bg-stone-600/40 max-w-[1300px] mx-auto rounded-xl">
          <div className="m-8 w-full md:w-1/2">
            <h2 className="text-3xl font-bold leading-tight gray-primary-color sm:text-4xl lg:text-5xl">
              Wie is <span>VETT</span>
            </h2>
            <p className="max-w-lg mt-3 text-xl text-white md:mt-8">
              Bij de opstart van "Vett" was onze missie om de normen van
              badkamerdesign te herdefiniëren: Belgisch vakmanschap met een
              internationaal design. Met de focus op handgemaakte baden en
              wastafels, vervaardigd met natuurlijke materialen en een oog voor
              design, willen we een uniek en luxueus product aanbieden aan onze
              klanten. Sam en Dries, de oprichters van Vett, brengen hun passie
              voor vakmanschap en esthetiek samen om unieke badkamers te creëren
              die zowel tijdloos als functioneel zijn. Met een toewijding aan
              vakmanschap, belooft het merk Vett een nieuwe standaard te zetten
              voor badkamerontwerp en design.
            </p>
          </div>
          <div className="m-8 w-full md:w-1/2">
            <img
              src={bathroom2}
              className="rounded-xl h-96 w-full object-cover"
              alt="VETT bathroom"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
