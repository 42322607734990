import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { NavLink } from "react-router-dom";

const vettLogoBlack = new URL("/src/assets/vettLogoBlack.jpg", import.meta.url);
const brochurePDF = new URL("/src/assets/brochure.pdf", import.meta.url); // Import the brochure

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const closeMenu = () => {
    setNav(false); // Close menu on link click
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 mx-auto max-w-[1500px] h-[8vh]">
      <div className="flex items-center justify-between h-16 lg:h-20 bg-[#223]/30 rounded-b-2xl rounded-t-2xl shadow-lg px-4">
        <div className="text-black text-xl sm:text-2xl">
          <a href="/" className="font-bold">
            <img src={vettLogoBlack} alt="vett logo" className="h-12 lg:h-20" />
          </a>
        </div>
        <ul className="hidden lg:flex lg:items-center lg:justify-center lg:space-x-10 text-2xl text-gray-700 font-medium">
          <li className="p-2">
            <NavLink
              to="/hero"
              onClick={closeMenu}
              className="hover:text-white"
            >
              Home
            </NavLink>
          </li>
          <li className="p-2">
            <NavLink
              to="/about"
              onClick={closeMenu}
              className="hover:text-white"
            >
              Over ons
            </NavLink>
          </li>
          <li className="p-2">
            <NavLink
              to="/products"
              onClick={closeMenu}
              className="hover:text-white"
            >
              Producten
            </NavLink>
          </li>
          <li className="p-2">
            <NavLink
              to="/contact"
              onClick={closeMenu}
              className="hover:text-white"
            >
              Contact
            </NavLink>
          </li>
          {/* Brochure Opens in a New Tab */}
          <li className="p-2">
            <a
              href={brochurePDF}
              target="_blank"
              rel="noopener noreferrer" // Added for security reasons
              className="hover:text-white transition-all"
              title="Open our brochure"
            >
              Brochure
            </a>
          </li>
        </ul>

        {/* Hamburger menu for tablet and mobile views */}
        <div
          onClick={handleNav}
          className="lg:hidden text-white z-50 cursor-pointer"
        >
          {nav ? <AiOutlineClose size={30} /> : <AiOutlineMenu size={30} />}
        </div>

        {/* Full-screen Menu (for mobile/tablet) */}
        <div
          className={`${
            nav
              ? "fixed top-0 left-0 w-full h-full bg-black/90 z-40 transition-all duration-300"
              : "hidden"
          }`}
        >
          <ul className="text-white flex flex-col items-center justify-center space-y-6 text-4xl h-full">
            <li>
              <NavLink to="/hero" onClick={closeMenu}>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/about" onClick={closeMenu}>
                Over ons
              </NavLink>
            </li>
            <li>
              <NavLink to="/products" onClick={closeMenu}>
                Producten
              </NavLink>
            </li>
            <li>
              <NavLink to="/contact" onClick={closeMenu}>
                Contact
              </NavLink>
            </li>
            <li>
              <a
                href={brochurePDF}
                target="_blank"
                rel="noopener noreferrer"
                onClick={closeMenu}
                className="hover:text-white"
              >
                Brochure
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
