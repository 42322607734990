import React, { useState } from "react";
import { AiFillHome, AiFillContacts } from "react-icons/ai";
import emailjs from "emailjs-com";

const juneImage = new URL("/src/assets/junedetail.jpg", import.meta.url);

const Contact = () => {
  const [successMessage, setSuccessMessage] = useState(""); // State for success message

  const sendEmail = (e) => {
    e.preventDefault(); // Prevent page reload

    emailjs
      .sendForm(
        "service_v7f7rrr", // Your service ID
        "template_tizjc6h", // Your template ID
        e.target, // Form element
        "QR8AtpAMLoH5iN7L6" // Your user ID
      )
      .then((res) => {
        console.log("Success:", res.text);
        e.target.reset(); // Reset the form fields
        setSuccessMessage("Bedankt voor uw bericht!"); // Set success message
      })
      .catch((err) => {
        console.log("Error:", err);
        setSuccessMessage("There was an error sending your message."); // Set error message
      });
  };

  return (
    <>
      <img
        src={juneImage}
        alt="june baththube"
        className="fixed mt-[-75px] w-screen -z-10"
      />
      <div className="pt-16 pb-10 sm:pt-16 sm:pb-16 lg:pb-16 md:h-[92vh]">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-10 bg-stone-600/40 rounded-3xl">
          <div className="mb-4">
            <div className="mb-6 max-w-3xl text-left sm:text-center md:mx-auto">
              <h2 className="text-3xl font-bold leading-tight gray-primary-color sm:text-4xl lg:text-5xl">
                Stuur ons een bericht
              </h2>
            </div>
          </div>

          <div className="flex justify-center">
            <div className="grid md:grid-cols-2">
              <div className="my-auto pr-6">
                <ul>
                  <li className="flex">
                    <AiFillHome className="w-[70px] h-auto text-white" />
                    <div className="m-5">
                      <h3 className="text-lg font-bold text-white">Adres</h3>
                      <p className="text-white">Laar 191</p>
                      <p className="text-white">2180 Ekeren</p>
                    </div>
                  </li>
                  <li className="flex">
                    <AiFillContacts className="w-[70px] h-auto text-white" />
                    <div className="m-5">
                      <h3 className="text-lg font-bold text-white">Contact</h3>
                      <p className="text-white">Mobiel: +32 (0) 485 270 907</p>
                      <p className="text-white">Email: info@vett.be</p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="max-w-6xl p-5 md:p-12" id="form">
                <h2 className="mb-4 text-2xl font-bold text-white">
                  Uw gegevens
                </h2>
                <form onSubmit={sendEmail}>
                  <div className="mb-6">
                    <div className="mx-0 mb-1 sm:mb-4">
                      <div className="mx-0 mb-1 sm:mb-4">
                        <input
                          type="text"
                          id="name"
                          autoComplete="given-name"
                          placeholder="uw naam"
                          className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 sm:mb-0"
                          name="name"
                        />
                      </div>
                      <div className="mx-0 mb-1 sm:mb-4">
                        <input
                          type="email"
                          id="email"
                          autoComplete="email"
                          placeholder="uw email adres"
                          className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 sm:mb-0"
                          name="email"
                        />
                      </div>
                    </div>
                    <div className="mx-0 mb-1 sm:mb-4">
                      <textarea
                        id="textarea"
                        name="message"
                        cols="30"
                        rows="5"
                        placeholder="schrijf hier uw bericht"
                        className="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 sm:mb-0"
                      ></textarea>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="w-full bg-primary-color text-white px-6 py-3 font-xl rounded-md sm:mb-0"
                    >
                      Verzenden
                    </button>
                  </div>
                </form>
                {successMessage && (
                  <p className="mt-8 text-red-500">{successMessage}</p>
                )}{" "}
                {/* Display success message */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
